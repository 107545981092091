import React, { useState, useEffect } from "react";

function NumberCountAnimation(currentKilometers) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount === currentKilometers.end) {
          clearInterval(interval);
          return prevCount;
        } else {
          return prevCount + 1;
        }
      });
    }, 5);
    return () => clearInterval(interval);
  }, [currentKilometers]);

  return <text className="emphasis">{count}</text>;
}

export default NumberCountAnimation;
