import './App.scss'
import { useEffect } from 'react'
import AOS from 'aos'

import SharedLayout from './Util/SharedLayout/SharedLayout'
import ComingSoon from './Pages/ComingSoon/ComingSoon'

import { Route, BrowserRouter, Routes } from 'react-router-dom'
import CrowdFund from './Pages/CrowdFund/CrowdFund'
import i18n from './Pages/CrowdFund/Translation'

function App() {
  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SharedLayout />}>
            <Route path="/" element={<CrowdFund />} />
            <Route path="*" element={<ComingSoon error={true} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
