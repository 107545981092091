import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  debug: true,
  fallbackLng: "gb",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  // language resources
  resources: {
    gb: {
      translation: {
        Title_1: "BE PART OF OUR",
        Paragraph_0:
          "Join us on our journey towards a sustainable future and solar victory! We are Top Dutch Solar Racing, a dedicated team of students on a mission to not only triumph at the Bridgestone World Solar Challenge but also to create a more sustainable world focused on sustainable mobility.",
        Paragraph_1:
          "Your invaluable community support empowers us to push the boundaries of solar technology and chase our dream of becoming champions. Each kilometer you support contributes to the vital logistical aspects of our adventure, like camping gear, and fuels the cutting-edge research and development required to outshine our competition.",
        Paragraph_2:
          "Together, we're not just building a race-winning solar vehicle we're nurturing a greener, more innovative future. By supporting us, you're helping to promote sustainable energy and inspire the next generation of forward-thinking innovators.",
        Paragraph_3:
          "We take pride in our commitment to transparency and accountability, ensuring you stay informed and engaged throughout our journey. Let's unite as a community to make a lasting impact on the world and pave the road to solar victory!",
        Button_about_us: "Find out more about us!",
        Counter: "We are almost there",
        Why_wait: "Why wait?",
        Together:
          "Every 10 kilometer you support fuels € 10  towards our solar journey!",
        Button_donate: "JOIN US NOW!",
        Other_leads:
          "Do you have any other good leads that might help up push the boundaries of solar racing?",
        Reach_out: "Don't hesitate to reach out to us at",
        Write: "Write Your Name in Solar History!",
        Imagine:
          "Imagine your name speeding through the Australian outback, proudly displayed on our convoy cars, part of an epic journey towards a brighter, sustainable future. This is not just a dream - it's a unique opportunity brought to you by Top Dutch Solar Racing!",
        Support: "Support us before September 1st for the opportunity!",
        Disclaimer:
          "Disclaimer: This is a representation. Real life size and placement may differ.",
        Whether:
          "Whether you're half a world away or just around the corner, your support will be felt and seen on the solar-powered journey of a lifetime. You will be there with us, under the hot Australian sun, as we compete in the Bridgestone World Solar Challenge, pushing the boundaries of sustainable mobility.",
        Special_thanks: "A special thank you to our top supporters",
        Thank_You: "Thank you",
        For_supporting: "for supporting us with",
        Thank_you_all: "Thank you to all our supporters",
      },
    },
    ro: {
      translation: {
        Title_1: "FIȚI PARTE DIN",
        Paragraph_0:
          " Însoțiți-ne în călătoria noastră spre un viitor sustenabil și spre victoria solară! Suntem Top Dutch Solar Racing, o echipă dedicată de studenți într-o misiune de a nu numai să triumfăm la Bridgestone World Solar Challenge, dar și de a crea o lume mai verde axată pe mobilitatea sustenabilă.",
        Paragraph_1:
          "Sprijinu inestimabil din partea comunității ne împuternicește să depășim limitele tehnologiei solare și să ne urmărim visul de a deveni campioni. Fiecare kilometru pe care îl sprijiniți contribuie la aspectele logistice vitale ale aventurii noastre, cum ar fi echipamentul de camping, și alimentează cercetarea și dezvoltarea tehnologiilor de ultimă generatie necesare pentru a castiga.",
        Paragraph_2:
          "Împreună, nu doar construim un vehicul solar care va câștiga cursa, ci și încurajăm un viitor mai verde și mai inovator. Prin sprijinul acordat, ne ajutați să promovăm energia sustenabilă și să inspirăm următoarea generație de inovatori.",
        Paragraph_3:
          "Suntem mândri de angajamentul nostru față de transparență și responsabilitate, asigurându-ne că sunteți informat și implicat pe tot parcursul călătoriei noastre. Să ne unim ca o comunitate pentru a avea un impact durabil asupra lumii și pentru a deschide drumul spre victoria solară!",
        Button_about_us: "Aflați mai multe despre noi!",
        Counter: "Suntem aproape acolo",
        Why_wait: "Ce mai aștepți?",
        Together:
          "Fiecare kilometru pe care îl sprijiniți alimentează 10 € spre aventura noastră solară!",
        Button_donate: "ALATURAȚI-VĂ ACUM!",
        Other_leads:
          "Aveți alte informații care ne-ar putea ajuta să ne depășim limitele?",
        Reach_out: "Nu ezitați să ne contactați la",
        Write: "Scrieți-vă numele în istoria solară!",
        Imagine:
          "Imaginați-vă numele dvs. care trece prin outback-ul australian, afișat cu mândrie pe mașinile noastre de convoi, parte a unei călătorii epice spre un viitor mai luminos și mai sustenabil. Aceasta nu este doar un vis - este o oportunitate unică oferită de Top Dutch Solar Racing!",
        Support:
          "Sprijiniți-ne înainte de 1 septembrie pentru acesta oportunitate!",
        Disclaimer:
          "Disclaimer: Imaginile sunt o reprezentare. Dimensiunea și amplasarea reală pot diferi.",
        Whether:
          "Fie că sunteți la jumătate de glob departare sau doar la colțul străzii, sprijinul dvs. va fi simțit și văzut în călătoria noastră cu energie solară. Veți fi acolo cu noi, sub soarele fierbinte al Australiei, în timp ce concurăm în Bridgestone World Solar Challenge, depășind limitele mobilității sustenabile.",
        Special_thanks: "Un mulțumesc special susținătorilor noștri de top",
        Thank_You: "Mulțumim",
        For_supporting: "pentru sprijinul acordat cu",
        Thank_you_all: "Mulțumim tuturor susținătorilor noștri",
      },
    },
    de: {
      translation: {
        Title_1: "Werde Teil unserer Reise",
        Paragraph_0:
          " Begleite uns auf unserer Reise in eine nachhaltige Zukunft und den Erflog der Solarenergie! Wir sind das Team Top Dutch Solar Racing, bestehend aus engagierten Studenten, die nicht nur das Ziel haben, bei der Bridgestone World Solar Challenge zu gewinnen, sondern auch eine nachhaltigere Welt mit Fokus auf umweltfreundlicher Mobilität zu schaffen.",
        Paragraph_1:
          "Dein Support hilft uns dabei, die Grenzen der Solartechnologie auszutesten und unseren Traum, Champions zu werden, zu verfolgen. Jeder von Dir unterstützte Kilometer trägt zu den wichtigen logistischen Aspekten unseres Abenteuers bei, wie beispielsweise Campingausrüstung, und treibt die hochmoderne Forschung und Entwicklung voran, die erforderlich ist, um unsere Konkurrenz zu übertreffen.",
        Paragraph_2:
          "Gemeinsam bauen wir nicht nur ein siegreiches Solarfahrzeug, sondern fördern auch eine grünere und innovativere Zukunft. Deine Unterstützung trägt dazu bei, nachhaltige Energie zu fördern und die nächste Generation zukunftsorientierter Innovatoren zu inspirieren.",
        Paragraph_3:
          "Wir sind stolz auf unser Engagement für Transparenz und Verantwortungsbewusstsein und möchten sicherstellen, dass du während unserer Reise stets informiert und eingebunden bist. Lass uns zusammen einen nachhaltigen Einfluss auf der Welt hinterlassen und den Weg zum Sieg der Solarenergie zu ebnen!",
        Button_about_us: "Mehr über uns!",
        Counter: "Wir haben es fast geschafft",
        Why_wait: "Also wieso warten?",
        Together:
          "Jeder von dir unterstützte Kilometer treibt €10 in unserem Solarabenteuer an!",
        Button_donate: "Schließe dich uns jetzt an!",
        Other_leads:
          "Hast du noch weitere vielversprechende Ideen, die uns helfen könnten, die Grenzen des Solarrennsports zu brechen?",
        Reach_out: "Zögere nicht, uns zu kontaktieren",
        Write: "Schreibe deinen Namen in die Geschichte des Solarrennsports!",
        Imagine:
          "Stell dir vor, dein Name rast durch das australische Outback, stolz auf unseren Konvoifahrzeugen präsentiert, als Teil einer epischen Reise in eine hellere, nachhaltige Zukunft. Dies ist nicht nur ein Traum - es ist eine einzigartige Gelegenheit, die dir von Top Dutch Solar Racing geboten wird! ",
        Support:
          "Unterstütze uns vor dem 1. September, um diese Chance wahrzunehmen!",
        Disclaimer:
          "Hinweis: Dies ist nur ein Beispiel. Die tatsächliche Größe und Platzierung können abweichen.",
        Whether:
          "Egal, ob du am anderen Ende der Welt oder gleich um die Ecke bist, deine Unterstützung wird auf unserer solarbetriebenen Reise fürs Leben spürbar und sichtbar sein. Du wirst mit uns unter der heißen australischen Sonne sein, während wir an der Bridgestone World Solar Challenge teilnehmen und die Grenzen der nachhaltigen Mobilität austesten.",
        Special_thanks: "Ein besonderes Dankeschön, an unsere Top Supporter",
        Thank_You: "Danke",
        For_supporting: "für die Unterstzütung von",
        Thank_you_all: "Dankeschön, an all unsere Supporter",
      },
    },
    es: {
      translation: {
        Title_1: "SÉ PARTE DE",
        Paragraph_0:
          "¡Únete a nosotrxs en nuestro camino hacia un futuro sostenible y una victoria solar! Somos Top Dutch Solar Racing, un equipo dedicado de estudiantes en una misión no sólo para triunfar en el Bridgestone World Solar Challenge, sino también para crear un mundo más sostenible centrado en la movilidad sostenible.",
        Paragraph_1:
          "Tu incalculable apoyo comunitario nos empodera para retar los límites de la tecnología solar y perseguir nuestro sueño de convertirnos en campeonxs. Cada kilómetro que contribuyes, nos ayuda en aspectos logísticos vitales para nuestra aventura, como equipamiento de acampada, además de alimentar una investigación y un desarrollo innovador necesario para sobresalir en la competición.",
        Paragraph_2:
          "Juntxs, no sólo construimos un coche solar ganador, sino que nutrimos un futuro más verde e innovador. Apoyándonos, nos ayudas a promover la energía sostenible e inspirar a la siguiente generación de innovadorxs y visionarixs.",
        Paragraph_3:
          "Nos enorgullecemos de nuestro compromiso con la transparencia y la responsabilidad, asegurándonos de que siempre te encuentres informadx e involucradx durante nuestra aventura. ¡Unámonos como comunidad para hacer un impacto duradero en este, nuestro planeta, y allanar el camino hacia la victoria solar!",
        Button_about_us: "¡Descubre más sobre nosotrxs!",
        Counter: "Casi estamos ahí",
        Why_wait: "¿Por qué esperar?",
        Together:
          "¡Cada kilómetro que apoyes aporta 10 € a nuestro viaje solar!",
        Button_donate: "¡ÚNETE A NOSOTRXS AHORA!",
        Other_leads:
          "¿Conoces de algún otro recurso que pueda ayudarnos a ampliar los límites de las carreras solares?",
        Reach_out: "No dudes en contactar con nosotrxs en",
        Write: "¡Escribe Tu Nombre en la Historia Solar!",
        Imagine:
          "Imagina tu nombre acelerando por los desiertos de Australia, orgullosamente expuesto en uno de nuestros coches de escolta, formando parte de este viaje épico hacia un futuro más brillante y sostenible. ¡Esto no es sólo un sueño - es una oportunidad única que ofrecemos como Top Dutch Solar Racing!",
        Support:
          "¡Únete a nosotrxs antes del 1 de septiembre para podar optar a esta oportunidad!",
        Disclaimer:
          "Descargo de responsabilidades: Esto es una representación. El tamaño real y la posición pueden variar.",
        Whether:
          "Ya sea si estás a medio mundo de distancia o a la vuelta de la esquina, tu apoyo se sentirá y será visto en este viaje alimentado por energía solar que promete ser una experiencia de vida. Estarás con nosotrxs, debajo del ardiente sol australiano, mientras competimos en el Bridgestone World Solar Challenge, ampliando los límites de la movilidad sostenible.",
        Special_thanks:
          "Un agradecimiento especial a nuestrxs mayores donantes",
        Thank_You: "Gracias",
        For_supporting: "por apoyarnos con",
        Thank_you_all: "Gracias a todxs nuestros donantes",
      },
    },
    nl: {
      translation: {
        Title_1: "WEES EEN DEEL VAN ONZE REIS",
        Paragraph_0:
          " Ga met ons mee op onze reis naar een duurzame toekomst en naar de overwinning op zonne-energie! Wij zijn Top Dutch Solar Racing, een toegewijd team van studenten met een missie om niet alleen te zegevieren bij de Bridgestone World Solar Challenge, maar ook om een ​​duurzamere wereld te creëren gericht op duurzame mobiliteit.",
        Paragraph_1:
          "Uw onschatbare steun stelt ons in staat om de grenzen van de zonnetechnologie te verleggen en onze droom om kampioen te worden na te jagen. Elke kilometer die u steunt, draagt ​​bij aan de essentiële logistieke aspecten van ons avontuur, zoals kampeerspullen, en voedt onze geavanceerde onderzoeken en de ontwikkelingen die nodig zijn om onze concurrentie te overtreffen.",
        Paragraph_2:
          "Samen bouwen we niet alleen een racewinnende zonnewagen, we koesteren ook een groenere, meer innovatieve toekomst. Door ons te steunen, helpt u duurzame energie te promoten en inspireert u de volgende generatie vooruitstrevende innovators.",
        Paragraph_3:
          "We zijn trots op onze toewijding aan transparantie en verantwoording, zodat u tijdens onze reis op de hoogte en betrokken blijft. Laten we samenwerken om een ​​blijvende impact op de wereld te hebben en de weg naar zonne-overwinning te effenen!",
        Button_about_us: "Ontdek meer over ons!",
        Counter: "We zijn er bijna",
        Why_wait: "Dus waarom nog wachten?",
        Together:
          "Elke kilometer die u steunt, levert € 10 brandstof op voor onze zonnereis!",
        Button_donate: "Doe met ons mee!",
        Other_leads:
          "Heb je nog andere goede aanwijzingen die kunnen helpen om de grenzen van zonneracen te verleggen?",
        Reach_out: "Aarzel niet om contact met ons op te nemen via",
        contact: "Aarzel niet om contact met ons op te nemen via",
        Write: "Schrijf uw naam in zonne energie geschiedenis!",
        Imagine:
          "Stel je voor dat je naam door de Australische outback raast, trots weergegeven op onze konvooiwagens, onderdeel van een epische reis naar een mooiere, duurzame toekomst. Dit is niet zomaar een droom - het is een unieke kans aangeboden door Top Dutch Solar Racing!",
        Support: "Steun ons vóór 1 september voor deze kans!",
        Disclaimer:
          "Disclaimer: dit is een weergave. Echte grote en plaatsing kunnen afwijken.",
        Whether:
          "Of je nu een halve wereld verwijderd bent of net om de hoek, je steun zal voelbaar en gezien worden tijdens de reis van je leven op zonne-energie. U zult bij ons zijn, onder de hete Australische zon, terwijl we strijden in de Bridgestone World Solar Challenge, waarbij we samen de grenzen van duurzame mobiliteit verleggen.",
        Special_thanks: "Een speciaal bedankje aan onze top supporters!",
        Thank_You: "Dankjewel!",
        For_supporting: "Dat u ons steunt met",
        Thank_you_all: "Bedankt aan al onze supporters",
      },
    },
    cn: {
      translation: {
        Title_1: "成为我们的一部分",
        Paragraph_0:
          " 加入我们可持续发展和太阳能源精彩的旅程! 我们是 Top Dutch Solar Racing, 一个不光将赢得太阳能竞赛世界冠军作为目标也希望推动新能源交通的发展的学生团队。",
        Paragraph_1:
          " 您的无价的支持在推动太阳能科技最新发展的同时也会帮助我们实现成为世界冠军的梦想. 您所支持的每公里路程将会贡献在我们十分重要的后勤部分比如露营工具, 以为我们精彩的竞赛所需要的研究和发展助力.",
        Paragraph_2:
          "在一起， 我们在建造一辆世界冠军太阳能车的同时我们也在推动一个更加绿色和创新的未来. 帮助我们您将帮助我们推动可持续能源的发展和启发下一代的创新者.",
        Paragraph_3:
          "我们为我们的价值观“透明”和“责任”做出承诺, 以便您能更好的参与我们的旅程. 让我们团结一致作为一个社区为世界做出长远的影响和为太阳能源的胜利铺平道路",
        Button_about_us: "发现更多关于我们的团队!",
        Counter: "我们就快到了",
        Why_wait: "在等什么？",
        Together: "您支持的每一公里都会为我们的太阳能之旅提供 10 欧元的燃料！",
        Button_donate: "现在加入我们!",
        Other_leads: "您有其他资源可能帮助我们推动太阳能竞赛的前进吗?",
        Reach_out: "不要犹豫，联系我们",
        Write: "在太阳能历史上写下您的名字!",
        Imagine:
          "想象您的名字在澳大利亚内陆奔驰, 骄傲的写在我们的护航车队上, 成为我们朝向可持续发展未来的精彩旅程的一部分. 这不是一个梦-这是 Top Dutch Solar Racing 带给你独一无二的机会!",
        Support: "在九月一号前支持我们!",
        Disclaimer: "声明: 这只是一个展示. 真实大小和位置可能与图片不一致.",
        Whether:
          "不管远近, 您的支持将会被我们在太阳能源之旅中感受到和看到. 您将与我们同在, 在澳大利亚的烈日下, 在我们在世界太阳能大赛竞赛时推动可持续交通的发展时.",
        Special_thanks: "特别的感谢给我们最重要的支持者",
        Thank_You: "谢谢",
        For_supporting: "为支持我们",
        Thank_you_all: "谢谢我们所有的支持者",
      },
    },
    gr: {
      translation: {
        Title_1: "ΓΙΝΕ ΜΕΡΟΣ ΜΑΣ",
        Paragraph_0:
          " Ελάτε μαζί μας στο ταξίδι προς ένα βιώσιμο μέλλον και ηλιακή μας νίκη! Είμαστε η Top Dutch Solar Racing, μια αφοσιωμένη ομάδα μαθητών σε μια αποστολή όχι μονο την εστία της νίκης του Bridgestone World Solar Challenge αλλά και να δημιουργία ενός πιο βιώσιμο κόσμο με επίκεντρο τη βιώσιμη κινητικότητα.",
        Paragraph_1:
          "Η ανεκτίμητη κοινοτική σας υποστήριξη μάς δίνει τη δυνατότητα να ξεπεράσουμε τα όρια της ηλιακής τεχνολογίας και να κυνηγήσουμε το όνειρό μας, να γίνουμε πρωταθλητές. Κάθε χιλιόμετρο που υποστηρίζεται συμβάλλει στις ζωτικές υλικοτεχνικές πτυχές της περιπέτειες μας, όπως εξοπλισμός κατασκήνωσης, τα οποία τροφοδοτεί την έρευνα και την ανάπτυξη αιχμής που απαιτείται για να ξεπεράσουμε τους ανταγωνιστές μας.",
        Paragraph_2:
          "Μαζί, δεν κατασκευάζουμε απλώς ένα ηλιακό όχημα που κερδίζει αγώνες, αλλά καλλιεργούμε ένα πιο πράσινο, πιο καινοτόμο μέλλον. Υποστηρίζοντας μας, βοηθάτε στην προώθηση της βιώσιμης ενέργειας και εμπνέετε την επόμενη γενιά προνοητικών καινοτόμων.",
        Paragraph_3:
          "Είμαστε υπερήφανοι για τη δέσμευσή μας σε αυτο το έργο και για την διαφάνεια που έχουμε κρατήσει και υπευθυνότητα, διασφαλίζουμε ότι θα παραμείνετε ενημερωμένοι και αφοσιωμένοι καθ' όλη τη διάρκεια του ταξιδιού μας. Ας ενωθούμε ως κοινότητα για να κάνουμε ένα μόνιμο αντίκτυπο στον κόσμο και να ανοίξουμε το δρόμο προς την ηλιακή νίκη!",
        Button_about_us: "Μάθετε περισσότερα για εμάς!",
        Counter: "Είμαστε σχεδόν εκεί",
        Why_wait: "Γιατί λοιπόν να περιμένετε;",
        Together:
          "Κάθε χιλιόμετρο που υποστηρίζετε τροφοδοτεί με καύσιμα € 10 για το ηλιακό μας ταξίδι!",
        Button_donate: "ΕΓΓΡΑΦΕΙΤΕ ΜΑΖΙ ΜΑΣ ΤΩΡΑ!",
        Other_leads:
          "Μηπως έχετε κάποιες άλλες συνδέσεις που θα μπορούσαν να μας βοηθήσουν να ξεπεράσουμε τα όρια των ηλιακών αγώνων;",
        Reach_out: "Μη διστάσετε να επικοινωνήσετε μαζί μας στο",
        Write: "Γράψε το Όνομά σου στην Ηλιακή Ιστορία!",
        Imagine:
          "Φανταστείτε το όνομά σας να περνά με ταχύτητα στην αυστραλιανή περιοχή, που εμφανίζεται με περηφάνια στην σειρα ουλής τον αυτοκηνητον μας, μέρος ενός επικού ταξιδιού προς ένα φωτεινότερο, βιώσιμο μέλλον. Αυτό δεν είναι απλώς ένα όνειρο - είναι μια μοναδική ευκαιρία που σας έφερε στο Top Dutch Solar Racing!",
        Support: "Υποστηρίξτε μας πριν την 1η Σεπτεμβρίου για την ευκαιρία!",
        Disclaimer:
          "Αποποίηση ευθύνης: Αυτή είναι μια αντιπροσώπευση. Το πραγματικό μέγεθος και η τοποθέτηση μπορεί να διαφέρουν.",
        Whether:
          "Είτε βρίσκεστε μισό κόσμο μακριά είτε στο καντούνη , η υποστήριξή σας θα είναι αισθητή και ορατή στο ταξίδι μιας ζωής με ηλιακή ενέργεια. Θα είστε εκεί μαζί μας, κάτω από τον καυτό αυστραλιανό ήλιο, καθώς αγωνιζόμαστε στο Bridgestone World Solar Challenge, υπερβαίνοντας τα όρια της βιώσιμης κινητικότητας.",
        Special_thanks:
          "Ένα ιδιαίτερο ευχαριστώ στους κορυφαίους υποστηρικτές μας",
        Thank_You: "Ευχαριστώ",
        For_supporting: "για την υποστήριξη μας",
        Thank_you_all: "Ευχαριστούμε όλους τους υποστηρικτές μας",
      },
    },
    ld: {
      translation: {
        Title_1: "Wês diel fan ús",
        Paragraph_0:
          " Doch mei oan ús aventoer rjochting ien duorsume takomst en ien wraldkampioenskip oerwinning! Wy binne Top Dutch Solar Racing, ien tawijde groep studinten op in missy om net allinnich de Bridgestone World Solar Challenge te winnen mar om de wrâld duorsummer te meitsje, te begjinnen mei duorsummer ferfier.",
        Paragraph_1:
          "Jim net te missen mienskip hulp jout us de kracht om de grizen fan sinne technologie te fersetten en use dream om kampioen te wurden efternei te gean. Else kilometer wermij jim us helpe, helpt us mei de fitale logistike dielen fan us aventoer, lykas kampear spul, en helpt use oendersiek en ûntwikkeling die nedig is om use kompetysje efter us te litten.",
        Paragraph_2:
          "Tegearre meitsje wy net alinne ien race winnende sinne auto, wy meitsje ek ien grienere, meer ynnovatyffe takomst. Troch ús te stypjen, helpe jim om ien duorsume energie te befoarderjen en ynspirearje jim ien nije generatije fan fourut tinkende fernijers.",
        Paragraph_3:
          "Wy binne grutsk op use ynset op transparânsje en ferabtwurding, om seker te witten dat jim ynformearre en dwaande binne yn ús hiele reis. Lit us bie elkoander komme as ien mienskip om ien bliewende ynfloed te setten op disse wrald en ien begin te setten foar ien sinniche oerwinning",
        Button_about_us: "Fyn mear oer ús ut!",
        Counter: "Wy binne der hast",
        Why_wait: "Wêrom wachtsje?",
        Together:
          "Elke kilometer dy't jo stypje brânstoffen €10 nei ús sinne reis!",
        Button_donate: "DOCH MEI ÚS MEI!",
        Other_leads:
          "Hawwe jim oare ideeën die us helpe kinne om de grinzen fan sinne racing te fersetten?",
        Reach_out: "Bin net bang om kontact mei ús op te nimmen",
        Write: "Skriuw dyn namme ien sinne skiednis!",
        Imagine:
          "Tink dy ien, jim namme riident troch de Australise Outback, grutsk werjûn op ús konfoai auto's, diel fan ien epysk aventoer rjochting ien ljochter, doursumere takomst. Dit is net alinne ien dream - it is ien unlike kâns brocht troch Top Dutch Solar Racing!",
        Support: "Help ús foar de earste fan septimber foar disse kâns!",
        Disclaimer:
          "Disclaimer: Dit is ien fertsjintwurdiging. Ien it echte libben kin de pleatsing oars weze.",
        Whether:
          "Wannear jim oan de oare kant fan de wârld binne of just krekt om de hoeke, jim hulp sil sjoen en fielt wurre op it sinne oandreaun aventoer fan it libben. Jim sil der foar ús weze, oender de hiete sinne ien Austraalje, at we stride ien de Bridgestone World Solar Challenge en de grinzen fersette fan doursum ferfier",
        Special_thanks: "Ien speciale dank foar ús grutste sponsors",
        Thank_You: "Tige dank",
        For_supporting: "Foar jim hulp mei",
        Thank_you_all: "Tige dank oan al ús oanhingers",
      },
    },
  },
});

export default i18n;
