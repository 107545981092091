import "./CrowdFund.scss";
import AnimatedMap from "./AnimatedMap";
import { useRef, useEffect, useState } from "react";

const CrowdFund = () => {
  const [kilometers, setKilometers] = useState(0);
  //Functions that makes a http request to the backend to get the number of kilometers
  //that have been purchased and stores in the state
  const getKilometers = async (setKilometers) => {
    const response = await fetch(
      "https://us-central1-tdsr-crowdfunding.cloudfunctions.net/getAmount"
    );
    const data = await response.json();

    setKilometers(data.total * 10);
  };
  useEffect(() => {
    getKilometers(setKilometers);
  }, []);
  return (
    <>
      <AnimatedMap kilometers={kilometers} />
    </>
  );
};

export default CrowdFund;
