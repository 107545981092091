import "./CrowdFund.scss";
import { useRef, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useTranslation } from "react-i18next";

import Van from "../../Resources/Pics/CSP/Van.jpg";
import Van2 from "../../Resources/Pics/CSP/Van2.jpg";
import Van3 from "../../Resources/Pics/CSP/Van3.jpg";

function BePartOfTheRace(props) {
  const t = props.translator;
  return (
    <>
      <div className="container simple-section">
        <div className="row simple-section-header">
          <h1>{t("Write")}</h1>
        </div>
        <div className="row simple-section-body medium">
          <p>
            {t("Imagine")}
            <br />
            <text className="emphasis">{t("Support")} </text>
          </p>
          {/* Lazy load the Van image  with a caption*/}
          <div className="row justify-content-center">
            <div className="col-4">
              <LazyLoadImage
                src={Van}
                placeholderSrc={Van}
                alt={"Your name on the convoy car"}
                effect="blur"
                className="img-fluid"
              />
            </div>
            <div className="col-4">
              <LazyLoadImage
                src={Van2}
                placeholderSrc={Van2}
                alt={"Your name on the convoy car"}
                effect="blur"
                className="img-fluid"
              />
            </div>
            <div className="col-4">
              <LazyLoadImage
                src={Van3}
                placeholderSrc={Van3}
                alt={"Your name on the convoy car"}
                effect="blur"
                className="img-fluid"
              />
            </div>
            <p className="caption">{t("Disclaimer")}</p>
            <p className="medium">{t("Whether")}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default BePartOfTheRace;
