import "./SharedLayout.scss";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import { Outlet } from "react-router-dom";
import ScrollButton from "../ScrollButton/ScrollButton";
import CookieConsent from "react-cookie-consent";

function SharedLayout() {
  return (
    <div className="App d-flex flex-column h-100">
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="TDSR-Awesome-Cookie"
        style={{
          background: "#63d471",
          color: "black",
          fontSize: "20px",
          fontFamily: "Roboto",
          zIndex: "9999",
        }}
        buttonStyle={{
          color: "#4e503b",
          fontSize: "16px",
          backgroundColor: "white",
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
      <Navbar />
      <Outlet />
      <Footer />
      <ScrollButton dark={false} />
    </div>
  );
}

export default SharedLayout;
