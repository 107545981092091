import "./CrowdFund.scss";

import { useRef, useEffect, useState } from "react";

const Supporters = (props) => {
  const t = props.translator;
  const ref = useRef(null);
  //State that stores the names and amounts of the supporters of the campaign
  //The state is an array of objects with the following structure:
  //[{name: "John", amount: 10}, {name: "Jane", amount: 20}]
  const [supporters, setSupporters] = useState([]);
  //State that stores the total amount of money raised by the campaign
  const [totalAmount, setTotalAmount] = useState(0);
  //State that stores the total number of supporters of the campaign
  const [totalSupporters, setTotalSupporters] = useState(0);

  //State that stores the top 5 supporters of the campaign
  const [topSupporters, setTopSupporters] = useState([]);

  //Functions that makes a http request to the backend to get the supporters of the campaign
  //and stores in the state
  const getSupporters = async (setSupporters) => {
    const response = await fetch(
      "https://us-central1-tdsr-crowdfunding.cloudfunctions.net/getCustomers"
    );
    const data = await response.json();
    setSupporters(data);
  };

  const getTopSupporters = async (setTopSupporters) => {
    const response = await fetch(
      "https://us-central1-tdsr-crowdfunding.cloudfunctions.net/getTopCustomers"
    )
      .then((response) => response.json())
      .then((data) => setTopSupporters(data))
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getSupporters(setSupporters);
    getTopSupporters(setTopSupporters);
  }, []);
  let currentColor = "odd";
  return (
    <>
      <div className="container simple-section">
        <div className="row simple-section-header">
          <h1>{t("Special_thanks")}</h1>
        </div>
        <div className="row simple-section-body">
          <div className="col-12 supporters">
            <div className="supporter-list">
              {topSupporters.map((supporter, index) => {
                return (
                  <div className={`supporter-top row`} key={index}>
                    <div className="col">
                      <p>{t("Thank_You")} </p>
                    </div>
                    <div className="col">
                      <p>
                        <text className="emphasis-supporter-top ">
                          {" "}
                          {supporter.name}
                        </text>
                      </p>
                    </div>
                    <div className="col">
                      <p> {t("For_supporting")}</p>
                    </div>
                    <div className="col">
                      <p>
                        {" "}
                        <text className="emphasis-supporter-top">
                          {" "}
                          {supporter.amount * 10}
                        </text>{" "}
                        km
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="container simple-section">
        <div className="row simple-section-header">
          <h1>{t("Thank_you_all")}</h1>
        </div>
        <div className="row simple-section-body">
          <div className="col-12 supporters">
            <div className="supporter-list">
              {supporters.map((supporter, index) => {
                currentColor = currentColor === "odd" ? "even" : "odd";
                return (
                  <div className={`supporter ${currentColor} row`} key={index}>
                    <div className="col">
                      <p>{t("Thank_You")} </p>
                    </div>
                    <div className="col">
                      <p>
                        <text className="emphasis-supporter ">
                          {" "}
                          {supporter.name}
                        </text>
                      </p>
                    </div>
                    <div className="col">
                      <p> {t("For_supporting")}</p>
                    </div>
                    <div className="col">
                      <p>
                        {" "}
                        <text className="emphasis-supporter ">
                          {" "}
                          {supporter.amount * 10}
                        </text>{" "}
                        km
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Supporters;
